import React from 'react'
import { graphql } from 'gatsby'
import EpisodeList from 'components/EpisodeList'

const Podcast = ({ data, pageContext }) => {
  return <EpisodeList data={data} paginationInfo={pageContext} />
}

export const pageQuery = graphql`
  query episodeListQuery2($skip: Int!, $limit: Int!) {
    allContentfulPodcastPage {
      edges {
        node {
          title
          description {
            json
          }
        }
      }
    }
    allContentfulPodcastEpisode(
      sort: { fields: episodeNumber, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export default Podcast
